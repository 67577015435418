import React from "react";
import { _t } from "../../../Common/components/InjectIntlContext";
import { CurrencyLabel } from "../../../Common/components";
import classNames from "classnames";

const DashboardTotalItem = ({ values, titles }: any) => {
  return (
    <div className="dashboard-total-item">
      <div className="cell-partner-income cell-partner-income--sports">
        {titles[0] && (
          <div
            className={classNames("cell-partner-income__total", {
              single: !values[1],
            })}
          >
            <p className="cell-partner-income__total-title">{_t(titles[0])}</p>
            <p className="cell-partner-income__total-value">
              <CurrencyLabel value={values[0]} />
            </p>
          </div>
        )}
        {titles[1] && (
          <div
            className={classNames("cell-partner-income__total", {
              single: !values[0],
            })}
          >
            <p className="cell-partner-income__total-title">{_t(titles[1])}</p>
            <p className="cell-partner-income__total-value">
              <CurrencyLabel value={values[1]} />
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default DashboardTotalItem;
