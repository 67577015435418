import React from "react";
import { Card, Row, Col, Spin, List, Typography } from "antd";
import { lineOptions, isCurrencyField } from "../../constants";
import { useFilters } from "../../hooks";
import { CurrencyLabel } from "../../../Common/components";
import { _t } from "../../../Common/components/InjectIntlContext";
import { CellTotals } from "../../../../common/components";
import { DashboardTotalItem } from "../DashboardTotalItem";

const CardItem = ({ label, children }) => (
  <List.Item style={{ background: "white", height: "100%" }}>
    <List.Item.Meta
      style={{ textAlign: "center" }}
      title={
        <Typography.Title level={4} style={{ marginBottom: 0 }}>
          {children}
        </Typography.Title>
      }
      description={label}
    />
  </List.Item>
);

export function TableTotals({ isLoading, totals }) {
  const [{ totals: totalsColumns }] = useFilters();
  const lineOptionsTranslated = lineOptions(_t);
  return (
    <div className="dashboard-totals">
      {totals && (
        <DashboardTotalItem
          values={[totals.ggr, totals.ngr]}
          titles={["ggr", "ngr"]}
        />
      )}
      {totals && (
        <DashboardTotalItem
          values={[totals.ggr_casino, totals.tax_casino]}
          titles={["ggr_casino", "tax_casino"]}
        />
      )}
      {totals && (
        <DashboardTotalItem
          values={[totals.bets_sport, totals.tax_sports]}
          titles={["bets_sport", "tax_sports"]}
        />
      )}
      <div className="dashboard-totals__grid">
        {lineOptionsTranslated
          .filter((option) => totalsColumns.includes(option.value))
          .map((column) => {
            const value = totals?.[column.value] || 0;
            return (
              <div key={column.value}>
                <div className="dashboard-totals-item">
                  <p className="dashboard-totals-item__title">{column.label}</p>
                  <p className="dashboard-totals-item__value">
                    {isCurrencyField(column.value) ? (
                      <CurrencyLabel value={value} />
                    ) : (
                      value
                    )}
                  </p>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}

export function GamersTableTotals({
  isLoading,
  totals,
  totalsColumns,
}: {
  isLoading: boolean;
  totals: any;
  totalsColumns: string[];
}) {
  return (
    <div className="dashboard-totals dashboard-totals--gamers">
      <div className="dashboard-totals__grid">
        {totals &&
          totalsColumns.map((column) => {
            const value = totals[column] || 0;
            return (
              <div key={column}>
                <div className="dashboard-totals-item">
                  <p className="dashboard-totals-item__title">{_t(column)}</p>
                  <p className="dashboard-totals-item__value">
                    {isCurrencyField(column) ? (
                      <CurrencyLabel value={value} />
                    ) : (
                      value
                    )}
                  </p>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}
