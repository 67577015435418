import axiosClient from "../../../../system/helpers/axiosConfig";
import FileSaver from "file-saver";

function downloadCSV(blob, filename) {
  const reader = new FileReader();
  reader.onload = function (event) {
    let csvData: any = event.target.result;

    // Prepend a tab character to numeric values to prevent Excel from misinterpreting them
    csvData = csvData
      .split("\n")
      .map((line) =>
        line
          .split(",")
          .map((value) => {
            // Check if the value is a number that could be misinterpreted as a date
            if (!isNaN(value) && value.trim() !== "" && value.includes(".")) {
              return `\t${value}`;
            }
            return value;
          })
          .join(",")
      )
      .join("\n");

    // Add the separator definition and a newline if not already present
    if (!csvData.startsWith("sep=,")) {
      csvData = "sep=,\n" + csvData;
    }

    // Add the separator definition and a newline if not already present
    if (!csvData.startsWith("sep=")) {
      csvData = "sep=,\n" + csvData;
    }

    // Create a new Blob from the modified data
    const newBlob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });

    FileSaver.saveAs(newBlob, filename);
  };
  // Read the original Blob as text
  reader.readAsText(blob);
}

export const getReportFile = (api, params, fileName) => {
  return axiosClient
    .post(api, params, {
      responseType: "blob",
    })
    .then((r) => {
      if (params.export === "csv") {
        downloadCSV(r.data, `${fileName}_${params.from}_${params.to}.csv`);
      } else {
        FileSaver.saveAs(
          r.data,
          `${fileName}_${params.from}_${params.to}.xlsx`
        );
      }
    });
};
